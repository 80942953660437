<template>
  <div class="wrapper" id="upgrade">
    <ctheader></ctheader>
    <div ref="upgrade" class="upgrade">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="全部类型">
          <el-select
            v-model="formInline.client_type"
            placeholder="全部类型"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in typeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本名称">
          <el-input
            v-model="formInline.version_name"
            placeholder="版本名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add" size="small">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="upgrade_list"
        style="width: 100%"
        :header-cell-style="getRowClass"
      >
        <el-table-column fixed prop="version_name" label="版本名称">
        </el-table-column>
        <el-table-column prop="version_num" label="版本号"> </el-table-column>
        <el-table-column prop="version_num" label="是否静默升级">
          <template slot-scope="scope">
            {{ scope.row.is_silent_escalation == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="version_code" label="code"> </el-table-column>
        <el-table-column prop="city" label="设备类型">
          <template slot-scope="scope">
            {{ scope.row.client_type == 2 ? "商户屏" : scope.row.client_type == 2 ?"数据大屏" :"安卓POS" }}
          </template>
        </el-table-column>
        <el-table-column label="企业">
          <template slot-scope="scope">
            {{ scope.row.whitelists.map((item) => item.name).join(",") }}
          </template>
        </el-table-column>
        <el-table-column prop="zip" label="市场">
          <template slot-scope="scope">
            {{ scope.row.orgz_white_lists.map((item) => item.name).join(",") }}
          </template>
        </el-table-column>
        <el-table-column prop="zip" label="设备">
          <template slot-scope="scope">
            {{ scope.row.pos_white_lists.map((item) => item.name).join(",") }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :start="formInline.start"
        :total="upgrade_total"
        :page-length="formInline.length"
        :pageCallback="getUpgradeList"
      ></pagination>
    </div>
  </div>
</template>

<script>
import ctheader from "../../components/ctheader";
import router from "../../router";
import * as URL from "../../modules/URLs";
import APIs from "@/modules/APIs";
import { apiPost } from "../../modules/utils";
import pagination from "../../components/pagination";

export default {
  components: { ctheader, pagination },
  data() {
    return {
      formInline: {
        action: "lists",
        start: 0,
        length: 10,
      },
      typeList: [
        {
          id: 3,
          name: "安卓POS",
        },
        {
          id: 2,
          name: "商户屏",
        },
        // {
        //   id: 8,
        //   name: "数据大屏",
        // },
        // {
        //   id: 6,
        //   name: "导视屏",
        // },
        // {
        //   id: 10,
        //   name: "质检屏",
        // },
        // {
        //   id: 9,
        //   name: "查询机",
        // },
      ],
      upgrade_list: [],
      upgrade_total: 0,
    };
  },
  mounted() {
    this.getUpgradeList();
  },
  methods: {
    query() {
      this.formInline.start = 0;
      this.getUpgradeList();
    },
    add() {
      router.push(URL.EquipmentUpgradeAdd);
    },
    edit(val) {
      router.push(URL.EquipmentUpgradeAdd + "/" + val.id);
    },
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #e9f2f1;font-size:12px;color:#525e6e;border:0;font-weight:normal";
      } else {
        return "";
      }
    },
    getUpgradeList(index = 0) {
      this.formInline.action = "lists";
      this.formInline.start = index;
      let $this = this;
      apiPost({
        url: APIs.UPDATE,
        data: $this.formInline,
        success(res) {
          if (res.code === "0") {
            $this.upgrade_list = res.data;
            $this.upgrade_total = res.total;
          } else {
            $this.upgrade_list = [];
            $this.upgrade_total = 0;
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
  },
};
</script>

<style lang="less">
.upgrade {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
  .el-select .el-input.is-focus .el-input__inner {
    border-color: rgb(37, 120, 115);
  }
  .el-input--small .el-input__inner:focus {
    border-color: #257873;
  }
  .el-button--primary {
    background: #257873;
    border-color: #257873;
  }
  .el-button--text {
    color: #257873;
  }
}
</style>
